@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,400;0,600;0,700;1,200;1,300&display=swap');

body {
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    line-height: 27px;
    color: $font;
}

h1,
.h1 {
    display: block;
    font-family: 'Titillium Web', sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: $font;
}

h2,
.h2 {
    display: block;
    font-family: 'Titillium Web', sans-serif;
    font-size: 23px;
    font-weight: bold;
    color: $font;
}

.subtitle {
    display: block;
    font-family: 'Titillium Web', sans-serif;
    font-style: italic;
    font-size: 18px;
    font-weight: 200;
}
