body {
    background: black;
}

header {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .logo {
        height: auto;
        width: 259px;
        align-self: center;
    }
}

.content-wrapper {
    background-image: url('../../images/bg-mobile.jpg');
    background-size: cover;
    flex: 1;

    @include lg {
        background-image: url('../../images/bg-desktop.jpg');
    }

    .section {
        padding-top: 60px;
    }

    .section-small {
        padding-top: 30px;
    }

    .sub-section {
        padding-top: 30px;
    }

    a {
        outline: 0;
        border: 0;
        transition: 0.5s;

        &:focus {
            border: 0;
            outline: 0;
        }
    }

    h2 {
        text-align: center;
        color: white;
        margin-bottom: 25px;
    }

    .subtitle {
        color: white;
        text-align: center;
        margin-bottom: 10px;
    }

    .alt-text {
        display: block;
        color: white;
        text-align: center;
        margin-top: 10px;
    }

    .description {
        color: white;
        text-align: center;
        display: block;
        font-size: 17px;
        padding: 0 50px;
        margin-top: -10px;
    }

    .download-ios,
    .download-android {
        text-align: center;
        transition: 0.2s;

        &:hover {
            transform: scale(1.1);
            transition: 0.2s;
        }
    }

    .download-ios {
        img {
            height: 57px;
            width: 170px;
        }
    }

    .download-android {
        img {
            height: 77px;
            width: 200px;
        }
    }

    .button {
        border-radius: 11px;
        display: block;
        min-height: 56px;
        padding: 5px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        color: white;
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
        width: fit-content;
        margin: 0 auto;
        transition: 0.2s;

        &:hover {
            transform: scale(1.1);
            transition: 0.2s;
            cursor: pointer;
        }

        .button-text {
            margin-left: 15px;
        }

        &.-phone {
            background-color: $phone;

            img {
                height: 27px;
                width: 29px;
            }
        }
        &.-whatsapp {
            background-color: $whatsapp;
            font-size: 18px;
            padding: 10px 20px 10px 20px;

            img {
                height: 30px;
                width: 30px;
            }
        }
        &.-email {
            background-color: $email;

            img {
                height: 17px;
                width: 24px;
            }
        }
        &.-website {
            background-color: $website;

            img {
                height: 27px;
                width: 42px;
            }
        }
        &.-chat {
            background-color: $chat;

            img {
                height: auto;
                width: 30px;
            }
        }

        &.-contact {
            background-color: $contact;

            img {
                height: 28px;
                width: 42px;
            }
        }

        &.-fb {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $fb;
            margin-bottom: 20px;

            img {
                height: 20px;
                width: auto;
            }
        }
    }
}

footer {
    margin-top: 50px;
    padding: 30px;
    background: black;
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
        transition: 0.2s;
    }
}
