//texts
$font: black;

//button backgrounds
$phone: #3a3d52;
$whatsapp: #25d366;
$email: #3a3d52;
$website: #f05941;
$contact: #1b1818;
$chat: #591fe2;
$fb: #4267b2;
