html,
#root,
#root > div {
    height: 100%;
}

body {
    overflow-x: hidden;
    height: 100%;
    overflow-y: scroll;
}

#root > div {
    display: flex;
    flex-flow: column;
}

// a {
//     color: $links;
//     text-decoration: none;

//     &:hover {
//         color: $links-hover;
//     }
// }
